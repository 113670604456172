<template>
  <div>
    <vue-headful
      title="Invoice: create or load template | Octoa"
    />
    <v-inner-header
      :is-saving.sync="isSaving"
      :is-saved.sync="isSaved"
      :redirect="projectId ? `/projects/${projectId}` : '/invoices'"
    />
    <div class="max-w-lg mx-auto pt-24 px-4">
      <div class="bg-white px-5 py-5 rounded shadow">
        <h1 class="text-xl">
          Create new invoice
        </h1>
        <router-link
          class="green-btn mt-6 inline-block"
          :to="projectId ? `/projects/${projectId}/invoice/create` : '/invoices/create'"
        >
          Create blank invoice
        </router-link>

        <div
          v-if="proposals && proposals.length > 0"
          class="mt-8"
        >
          <h2 class="mb-8 text-lg">
            Or create based on existing proposal
          </h2>

          <div
            v-for="proposal in proposals"
            :key="proposal.id"
            class="box mb-5 cursor-pointer max-w-sm border border-solid border-grey-d1 rounded px-4 py-4"
            @click="loadTemplate(proposal, 'proposal')"
          >
            <strong>{{ proposal.name }}</strong>
            <div class="my-1 mb-3 text-grey-semi-light">
              Accepted on {{ proposal.accepted_at | humanDate(timezone) }}
            </div>
            <div class="flex">
              <div class="w-1/2">
                <span
                  class="item-invoice-state"
                  :class="proposal.state"
                >{{ proposal.state }}</span>
              </div>
              <div class="w-1/2 text-right text-grey-darker">
                <vue-numeric
                  :currency-symbol-position="(currency.sign_after_price) ? 'suffix' : 'prefix'"
                  :precision="2"
                  read-only
                  :currency="currency.sign"
                  :minus="false"
                  :decimal-separator="currency.decimal_separator"
                  :thousand-separator="currency.thousand_separator"
                  :min="0"
                  :value="proposal.total"
                />
              </div>
            </div>
          </div>
        </div>

        <div
          v-if="invoices && invoices.length > 0"
          class="mt-8"
        >
          <h2 class="mb-8 text-lg">
            Or create based on invoice template
          </h2>
          <div
            v-for="invoice in invoices"
            :key="invoice.id"
            class="flex items-center mb-3 cursor-pointer border border-solid border-grey-d1 rounded px-4 py-4 hover:bg-grey-lighter"
            @click="loadTemplate(invoice, 'invoice')"
          >
            <img
              src="@/assets/img/icons/invoice.svg"
              width="25"
              class="align-middle"
            >
            <span class="ml-3 mt-1 inline-block">Invoice template: {{ invoice.name }}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import auth from '@/auth'
import { logException } from '@/helpers'
import { createCancelSource } from '@/http'

let cancelSource

export default {
  data(){
    return {
      isSaving: false,
      isSaved: false,
      loading: false,
      project: null,
      projectId: this.$route.params.id,
      proposals: {},
      invoices: {},
      user: auth.user(),
      timezone: '',
      currency: {},
    }
  },
  async mounted(){

    this.timezone = this.user.companySettings.timezone
    cancelSource = createCancelSource()

    try {
      const { data } = await this.$api.get('projects').load(this.projectId, {
        cancelToken: cancelSource.token
      })

      if (data.project && data.project.currency_id) {
        this.currency = data.project.currency;
      } else {
        this.currency = this.user.companySettings.currency;
      }

      this.project = data.project

    } catch(e){
      logException(e)
    }

    try {
      const { data } = await this.$api.get('projects').getProposalByState(this.projectId, 'accepted', {
        cancelToken: cancelSource.token
      })
      this.proposals = data.proposals

    } catch(e){
      logException(e)
    }

    try {
      const { data } = await this.$api.get('templateInvoice').list(this.user.company.id, {
        cancelToken: cancelSource.token
      })
      this.invoices = data.invoices
    } catch(e){
      logException(e)
    }

  },
  methods: {
    async loadTemplate(item, type){
       try {
        const data = await this.$store.dispatch('template/loadTemplate', item.id)
         if(data == 'success'){
          if(type == 'invoice'){
            if(this.project && item.currency_id != this.project.currency_id){
              const can = await this.$alert.confirm({
                title: 'The invoice template currency is different from the project.',
                text: `Do you want to continue to use the project currency: ${this.project.currency.name}?`,
              })

              if (can) {
                this.$router.push(`/projects/${this.projectId}/invoice/create?load=1`)
              }
            } else{
              if (this.project) {
                this.$router.push(`/projects/${this.projectId}/invoice/create?load=1`)
              } else {
                this.$router.push(`/invoices/create?load=1`)
              }
            }
          } else {
            if (this.project) {
              this.$router.push(`/projects/${this.projectId}/invoice/create?load=1&proposal=1`)
            } else {
              this.$router.push(`/invoices/create?load=1&proposal=1`)
            }
          }
        }
      } catch(e) {
        this.$toasted.global.api_error(e)
      }
    }
  }
}
</script>
